import React, { FC, ReactElement } from 'react'

import { graphql, Link, useStaticQuery } from 'gatsby';
import * as styles from './Work.module.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '~components/Layout';
import Moment from 'react-moment';

const WorkTemplate: FC<unknown> = (params): ReactElement => {
  const { article: articles } = useStaticQuery(query);
  //TODO: filters not working
  const article = articles.edges.find(edge => edge.node.id === params.pageContext.id).node;
  const image = getImage(article.image)

  const content = article.content && JSON.parse(article.content.raw)

  const renderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        // render the EMBEDDED_ASSET as you need
        const reference = article.content.references.find(item => item.contentful_id === node.data.target.sys.id)
        const image = getImage(reference.gatsbyImageData)
        return image && (
          <GatsbyImage
            alt={``}
            image={image}
            className={styles.image}
          />
        );
      },
    },
  };

  const imageBlock = (
    image && (
      <GatsbyImage
        alt={``}
        image={image}
        className={styles.image}
      />
    )
  );

  const desktopTitleBlock = (
    <div className={`${styles.title} ${styles.desktop}`}>
      <h1>{article.title}</h1>
      {/*<div className={styles.category}>*/}
      {/*  {article.category.title}*/}
      {/*  •*/}
      {/*  <Moment format="DD MMM YYYY">*/}
      {/*    {article.createdAt}*/}
      {/*  </Moment>*/}
      {/*</div>*/}
    </div>
  );

  const mobileTitleBlock = (
    <div className={`${styles.title} ${styles.mobile}`}>
      <h1>{article.title}</h1>
    </div>
  )

  const mobileCategory = (
    <div className={`${styles.mobile} ${styles.category}`}>
      <div className={styles.categoryCol}>
        <div className={styles.categoryTitle}>Client</div>
        <div>{article.title}</div>
      </div>
      <div className={styles.categoryCol}>
        <div className={styles.categoryTitle}>Headquarters</div>
        <div>{article.headquarters}</div>
      </div>
      <div className={styles.categoryCol}>
        <div className={styles.categoryTitle}>Industry</div>
        <div>{article.category.title}</div>
      </div>
      <div className={styles.categoryCol}>
        <div className={styles.categoryTitle}>Company size</div>
        <div>{article.companySize}</div>
      </div>
      {article.services && <div className={styles.categoryCol}>
        <div className={styles.categoryTitle}>Services we provided</div>
        <div>
          <ul>
            {article.services.map(service => (
              <li>{service.title}</li>
            ))}
          </ul>
        </div>
      </div>}
    </div>
  )

  return (
    <Layout>
      <div className={styles.root}>
        <div className={`${styles.bread} ${styles.desktop}`}>
          <Link to='/works'>Works</Link>
          <span>></span>
          <Link to={`/works/${article.slug}`}>{article.title}</Link>
        </div>
        {mobileTitleBlock}
        {imageBlock}
        {/*{mobileCategory}*/}
        <div className={styles.scrollable}>
          <div className={styles.content}>
            {desktopTitleBlock}
            <div className={styles.text}>
              {content && documentToReactComponents(content, renderOptions)}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WorkTemplate


export const query = graphql`
query WorkPageQuery($id: String) {
  article: allContentfulWork(filter: {id: {eq: $id}}) {
    edges {
      node {
        id
        slug
        title
        createdAt
        category {
          title
        }
        headquarters
        companySize
        services {
          title
        }
        content {
          raw
          references {
            gatsbyImageData
            contentful_id
          }
        }
        img {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 98
            placeholder: TRACED_SVG
          )
        }
      }
    }
  }
}
`
