// extracted by mini-css-extract-plugin
export var root = "Work-module--root--2cSQF";
export var bread = "Work-module--bread--3wD3m";
export var scrollable = "Work-module--scrollable--1ymN4";
export var desktop = "Work-module--desktop--JcMVL";
export var mobile = "Work-module--mobile--6MvVZ";
export var image = "Work-module--image--X_Xrd";
export var title = "Work-module--title--1-F5S";
export var category = "Work-module--category--VZo_d";
export var categoryCol = "Work-module--categoryCol--39lY-";
export var categoryTitle = "Work-module--categoryTitle--3fN9S";
export var content = "Work-module--content--3T1Uh";
export var text = "Work-module--text--yHwdH";